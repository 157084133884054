// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReactDOMRe = require("reason-react/src/ReactDOMRe.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var App$Workhours = require("./app.bs.js");
var Router$Workhours = require("./Router.bs.js");
var RegisterServiceWorker = require("./registerServiceWorker");

function register_service_worker(prim) {
  RegisterServiceWorker.default();
  return /* () */0;
}

ReactDOMRe.renderToElementWithId(ReasonReact.element(undefined, undefined, Router$Workhours.WithRouter.make((function (currentRoute) {
                return ReasonReact.element(undefined, undefined, App$Workhours.make(currentRoute, []));
              }))), "root");

RegisterServiceWorker.default();

exports.register_service_worker = register_service_worker;
/*  Not a pure module */
