// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Printf = require("bs-platform/lib/js/printf.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Models$Workhours = require("./Models.bs.js");
var DateUtil$Workhours = require("./DateUtil.bs.js");

function str(prim) {
  return prim;
}

function unfinishedWork(workList) {
  return workList.filter((function (w) {
                return w.duration === 0;
              }));
}

function latestOnDate(date, workList) {
  return Caml_option.undefined_to_opt(workList.filter((function (w) {
                        return DateUtil$Workhours.isSameDay(w.start, date);
                      })).sort((function (w1, w2) {
                      return DateUtil$Workhours.compareAsc(w1.start, w2.start);
                    })).pop());
}

function diffInHours(d1, d2) {
  return (d2.getTime() - d1.getTime()) / 1000 / 60 / 60;
}

function roundToQuarters(v) {
  return Math.round(v * 4) / 4;
}

function dateToDiff(d) {
  return roundToQuarters(diffInHours(d, new Date()));
}

function inProgressWorkToday(l) {
  return latestOnDate(new Date(), unfinishedWork(l));
}

function workInProgressToUpdatedWork(inProgress, hours) {
  return {
          id: inProgress.id,
          start: inProgress.start,
          duration: hours,
          lunch: hours > 4 ? 0.5 : 0,
          userid: inProgress.userid
        };
}

function diffInHours$1(start) {
  var now = new Date();
  var hours = (now.getTime() - start.getTime()) / (60 * 60 * 1000);
  return Math.round(hours * 4) / 4;
}

function stopWork(workList) {
  return /* UpdateWithSideEffects */Block.__(2, [
            /* Stopping */2,
            (function (self) {
                var match = inProgressWorkToday(workList);
                if (match !== undefined) {
                  var inProgress = match;
                  var duration = diffInHours$1(inProgress.start);
                  Models$Workhours.Work.save({
                            id: inProgress.id,
                            start: inProgress.start,
                            duration: duration,
                            lunch: inProgress.lunch === 0 && duration > 4 ? 0.5 : inProgress.lunch,
                            userid: inProgress.userid
                          }).then((function (work) {
                            return Promise.resolve(Curry._1(self.send, /* WorkStopped */Block.__(0, [work])));
                          })).catch((function (err) {
                          console.log("Error starting work: ", err);
                          return Promise.resolve(Curry._1(self.send, /* Failed */Block.__(1, ["Error starting work"])));
                        }));
                  return /* () */0;
                } else {
                  return Curry._1(self.send, /* Failed */Block.__(1, ["Trying to stop work not in progress"]));
                }
              })
          ]);
}

function startWork_001(self) {
  var newWork_start = new Date();
  var newWork = {
    id: undefined,
    start: newWork_start,
    duration: 0,
    lunch: 0,
    userid: undefined
  };
  Models$Workhours.Work.save(newWork).then((function (work) {
            return Promise.resolve(Curry._1(self.send, /* WorkStarted */Block.__(2, [work])));
          })).catch((function (err) {
          console.log("Error starting work: ", err);
          return Promise.resolve(Curry._1(self.send, /* Failed */Block.__(1, ["Error starting work"])));
        }));
  return /* () */0;
}

var startWork = /* UpdateWithSideEffects */Block.__(2, [
    /* Starting */1,
    startWork_001
  ]);

var component = ReasonReact.reducerComponent("Home");

function make(workList, handleAction, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              var interval = setInterval((function (param) {
                      return Curry._1(self.send, /* Tick */3);
                    }), 1000);
              return Curry._1(self.onUnmount, (function (param) {
                            clearInterval(interval);
                            return /* () */0;
                          }));
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var match = self.state;
              var tmp;
              if (typeof match === "number") {
                switch (match) {
                  case /* Initial */0 :
                      tmp = React.createElement("div", undefined, React.createElement("h1", undefined, "You are not working"), React.createElement("button", {
                                className: "button",
                                id: "startStop",
                                onClick: (function (_evt) {
                                    return Curry._1(self.send, /* StartWork */1);
                                  })
                              }, "Start working"));
                      break;
                  case /* Starting */1 :
                      tmp = React.createElement("div", undefined, "Starting work...");
                      break;
                  case /* Stopping */2 :
                      tmp = React.createElement("div", undefined, "Stopping work...");
                      break;
                  
                }
              } else {
                tmp = match.tag ? React.createElement("div", undefined, React.createElement("p", {
                            className: "error"
                          }, "Error: " + match[0]), React.createElement("button", {
                            onClick: (function (_evt) {
                                return Curry._1(self.send, /* Reset */2);
                              })
                          }, "Ok")) : React.createElement("div", undefined, React.createElement("h1", undefined, Curry._1(Printf.sprintf(/* Format */[
                                    /* Float */Block.__(8, [
                                        /* Float_f */0,
                                        /* No_padding */0,
                                        /* Lit_precision */[2],
                                        /* End_of_format */0
                                      ]),
                                    "%.2f"
                                  ]), dateToDiff(match[0][0])) + " hours and counting"), React.createElement("button", {
                            className: "button",
                            id: "startStop",
                            onClick: (function (_evt) {
                                return Curry._1(self.send, /* StopWork */0);
                              })
                          }, "Stop working"));
              }
              return React.createElement("div", {
                          className: "mb-l"
                        }, tmp);
            }),
          initialState: (function (param) {
              var match = inProgressWorkToday(workList);
              if (match !== undefined) {
                return /* Working */Block.__(0, [/* tuple */[
                            match.start,
                            new Date()
                          ]]);
              } else {
                return /* Initial */0;
              }
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                switch (action) {
                  case /* StopWork */0 :
                      return stopWork(workList);
                  case /* StartWork */1 :
                      return startWork;
                  case /* Reset */2 :
                      return /* Update */Block.__(0, [/* Initial */0]);
                  case /* Tick */3 :
                      var match = inProgressWorkToday(workList);
                      return /* Update */Block.__(0, [match !== undefined ? /* Working */Block.__(0, [/* tuple */[
                                        match.start,
                                        new Date()
                                      ]]) : /* Initial */0]);
                  
                }
              } else {
                switch (action.tag | 0) {
                  case /* WorkStopped */0 :
                      Curry._1(handleAction, /* WorkUpdate */Block.__(1, [action[0]]));
                      return /* Update */Block.__(0, [/* Initial */0]);
                  case /* Failed */1 :
                      return /* Update */Block.__(0, [/* Error */Block.__(1, [action[0]])]);
                  case /* WorkStarted */2 :
                      Curry._1(handleAction, /* WorkAdd */Block.__(0, [action[0]]));
                      return /* Update */Block.__(0, [/* Initial */0]);
                  
                }
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.str = str;
exports.unfinishedWork = unfinishedWork;
exports.latestOnDate = latestOnDate;
exports.roundToQuarters = roundToQuarters;
exports.dateToDiff = dateToDiff;
exports.inProgressWorkToday = inProgressWorkToday;
exports.workInProgressToUpdatedWork = workInProgressToUpdatedWork;
exports.diffInHours = diffInHours$1;
exports.stopWork = stopWork;
exports.startWork = startWork;
exports.component = component;
exports.make = make;
/* component Not a pure module */
