// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Models$Workhours = require("./Models.bs.js");
var ReasonReactCompat = require("reason-react/src/ReasonReactCompat.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var DateInput$Workhours = require("./DateInput.bs.js");

var initState_formData = {
  startDate: new Date(),
  endDate: new Date(),
  duration: ""
};

var initState = {
  formData: initState_formData,
  validationErrors: /* [] */0
};

function formDataToWorkRange(fd) {
  return {
          startDate: fd.startDate,
          endDate: fd.endDate,
          duration: Number(fd.duration)
        };
}

function NewWorkRange(Props) {
  var handleAction = Props.handleAction;
  var match = React.useState((function () {
          return initState;
        }));
  var setState = match[1];
  var state = match[0];
  return React.createElement("div", undefined, React.createElement("form", {
                  id: "registerHours"
                }, React.createElement("label", {
                      htmlFor: "startDate"
                    }, "Start date"), React.createElement(DateInput$Workhours.make, {
                      value: state.formData.startDate,
                      id: "startDate",
                      onChange: (function (date) {
                          return Curry._1(setState, (function (state) {
                                        var init = state.formData;
                                        return {
                                                formData: {
                                                  startDate: date,
                                                  endDate: init.endDate,
                                                  duration: init.duration
                                                },
                                                validationErrors: state.validationErrors
                                              };
                                      }));
                        })
                    }), React.createElement("label", {
                      htmlFor: "endDate"
                    }, "End date"), React.createElement(DateInput$Workhours.make, {
                      value: state.formData.endDate,
                      id: "endDate",
                      onChange: (function (date) {
                          return Curry._1(setState, (function (state) {
                                        var init = state.formData;
                                        return {
                                                formData: {
                                                  startDate: init.startDate,
                                                  endDate: date,
                                                  duration: init.duration
                                                },
                                                validationErrors: state.validationErrors
                                              };
                                      }));
                        })
                    }), React.createElement("label", {
                      htmlFor: "duration"
                    }, "Duration"), React.createElement("input", {
                      id: "duration",
                      value: state.formData.duration,
                      onChange: (function ($$event) {
                          var value = $$event.target.value;
                          return Curry._1(setState, (function (state) {
                                        var init = state.formData;
                                        return {
                                                formData: {
                                                  startDate: init.startDate,
                                                  endDate: init.endDate,
                                                  duration: value
                                                },
                                                validationErrors: state.validationErrors
                                              };
                                      }));
                        })
                    }), React.createElement("div", {
                      className: "actionButtons"
                    }, React.createElement("button", {
                          className: "button actionButtons__cancel",
                          type: "button",
                          onClick: (function (_event) {
                              return ReasonReactRouter.push("/");
                            })
                        }, "Cancel"), React.createElement("button", {
                          className: "button button--primary actionButtons__add",
                          type: "button",
                          onClick: (function (_event) {
                              Models$Workhours.Work.saveRange(formDataToWorkRange(state.formData)).then((function (workList) {
                                        $$Array.iter((function (w) {
                                                return Curry._1(handleAction, /* WorkAdd */Block.__(0, [w]));
                                              }), workList);
                                        ReasonReactRouter.push("/");
                                        return Promise.resolve(/* () */0);
                                      })).catch((function (err) {
                                      console.log("Error saving  work range: ", err);
                                      return Promise.resolve(/* () */0);
                                    }));
                              return /* () */0;
                            })
                        }, "Add"))));
}

var component = ReasonReact.statelessComponent("NewWorkRange");

function make(handleAction, children) {
  return ReasonReactCompat.wrapReactForReasonReact(NewWorkRange, {
              handleAction: handleAction
            }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = NewWorkRange;

exports.initState = initState;
exports.formDataToWorkRange = formDataToWorkRange;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* initState Not a pure module */
