// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");

function str(prim) {
  return prim;
}

function cred(m) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "username",
                m.username
              ],
              /* :: */[
                /* tuple */[
                  "password",
                  m.password
                ],
                /* [] */0
              ]
            ]);
}

var Encode = {
  cred: cred
};

var component = ReasonReact.reducerComponent("Login");

function make(_children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var state = param.state;
              return React.createElement("div", undefined, React.createElement("h1", undefined, "Login page"), React.createElement("input", {
                              value: state.username,
                              onChange: (function ($$event) {
                                  return Curry._1(send, /* UsernameKeyDown */Block.__(0, [$$event.target.value]));
                                })
                            }), React.createElement("input", {
                              type: "password",
                              value: state.password,
                              onChange: (function ($$event) {
                                  return Curry._1(send, /* PasswordKeyDown */Block.__(1, [$$event.target.value]));
                                })
                            }), React.createElement("button", {
                              className: "button button--primary",
                              onClick: (function (_event) {
                                  return Curry._1(send, /* LoginClicked */0);
                                })
                            }, "Login"));
            }),
          initialState: (function (_state) {
              return {
                      username: "",
                      password: ""
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                return /* UpdateWithSideEffects */Block.__(2, [
                          state,
                          (function (_self) {
                              fetch("/api/login", Fetch.RequestInit.make(/* Post */2, {
                                              "Content-Type": "application/json"
                                            }, Caml_option.some(JSON.stringify(cred(state))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(/* () */0)).then((function (prim) {
                                        return prim.text();
                                      })).then((function (_userid) {
                                      ReasonReactRouter.push("/");
                                      ((window.window.location.reload()));
                                      return Promise.resolve(/* () */0);
                                    }));
                              return /* () */0;
                            })
                        ]);
              } else if (action.tag) {
                return /* Update */Block.__(0, [{
                            username: state.username,
                            password: action[0]
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            username: action[0],
                            password: state.password
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.str = str;
exports.Encode = Encode;
exports.component = component;
exports.make = make;
/* component Not a pure module */
