// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var EditForm$Workhours = require("./editForm.bs.js");
var DateInput$Workhours = require("./DateInput.bs.js");

function str(prim) {
  return prim;
}

function formDataToUser(oldUser, formData) {
  return {
          id: oldUser.id,
          username: oldUser.username,
          name: oldUser.name,
          workhoursPerWeek: Number(formData.hoursPerWeek),
          balanceFrom: formData.balanceFrom
        };
}

function userToFormData(user) {
  return {
          hoursPerWeek: user.workhoursPerWeek.toString(),
          balanceFrom: user.balanceFrom
        };
}

function validate(formData) {
  return EditForm$Workhours.validateFloat("Hours per week", formData.hoursPerWeek);
}

var component = ReasonReact.reducerComponent("UserForm");

function make(user, saveUser, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var errors = self.state.validationErrors;
              return React.createElement("div", undefined, errors ? $$Array.of_list(errors).map((function (error, i) {
                                  return React.createElement("div", {
                                              key: i.toString(),
                                              className: "error"
                                            }, error);
                                })) : React.createElement("div", undefined), React.createElement("form", undefined, React.createElement("label", {
                                  htmlFor: "hoursPerWeek"
                                }, "Hours per week"), React.createElement("input", {
                                  id: "hoursPerWeek",
                                  value: self.state.formData.hoursPerWeek,
                                  onChange: (function ($$event) {
                                      return Curry._1(self.send, /* ChangeHoursPerWeek */Block.__(0, [$$event.target.value]));
                                    })
                                }), React.createElement("label", {
                                  htmlFor: "balanceFrom"
                                }, "Calculate balance from"), ReasonReact.element(undefined, undefined, DateInput$Workhours.Jsx2.make(self.state.formData.balanceFrom, "balanceFrom", (function (date) {
                                        return Curry._1(self.send, /* ChangeBalanceFrom */Block.__(1, [date]));
                                      }), [])), React.createElement("div", {
                                  className: "actionButtons"
                                }, React.createElement("button", {
                                      className: "button actionButtons__cancel",
                                      type: "button",
                                      onClick: (function (param) {
                                          return ReasonReactRouter.push("/");
                                        })
                                    }, "Cancel"), React.createElement("button", {
                                      className: "button button--primary",
                                      type: "button",
                                      onClick: (function (param) {
                                          return Curry._1(self.send, /* Validate */0);
                                        })
                                    }, "Save"))));
            }),
          initialState: (function (param) {
              return {
                      formData: userToFormData(user),
                      validationErrors: /* [] */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                var errors = EditForm$Workhours.validateFloat("Hours per week", state.formData.hoursPerWeek);
                if (errors) {
                  return /* Update */Block.__(0, [{
                              formData: state.formData,
                              validationErrors: errors
                            }]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (param) {
                                return Curry._1(saveUser, formDataToUser(user, state.formData));
                              })]);
                }
              } else if (action.tag) {
                var init = state.formData;
                return /* Update */Block.__(0, [{
                            formData: {
                              hoursPerWeek: init.hoursPerWeek,
                              balanceFrom: action[0]
                            },
                            validationErrors: state.validationErrors
                          }]);
              } else {
                var init$1 = state.formData;
                return /* Update */Block.__(0, [{
                            formData: {
                              hoursPerWeek: action[0],
                              balanceFrom: init$1.balanceFrom
                            },
                            validationErrors: state.validationErrors
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.str = str;
exports.formDataToUser = formDataToUser;
exports.userToFormData = userToFormData;
exports.validate = validate;
exports.component = component;
exports.make = make;
/* component Not a pure module */
