// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var ReasonReactCompat = require("reason-react/src/ReasonReactCompat.js");

function addZ(num) {
  if (num < 10) {
    return "0" + num.toString();
  } else {
    return num.toString();
  }
}

function toString(date) {
  return date.getFullYear().toString() + ("-" + (addZ(date.getMonth() + 1) + ("-" + addZ(date.getDate()))));
}

function toDate(prim) {
  return new Date(prim);
}

function DateInput(Props) {
  var value = Props.value;
  var id = Props.id;
  var onChange = Props.onChange;
  return React.createElement("input", {
              id: id,
              type: "date",
              value: toString(value),
              onChange: (function ($$event) {
                  return Curry._1(onChange, new Date($$event.target.value));
                })
            });
}

var component = ReasonReact.statelessComponent("DateInput");

function make(value, id, onChange, children) {
  return ReasonReactCompat.wrapReactForReasonReact(DateInput, {
              value: value,
              id: id,
              onChange: onChange
            }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = DateInput;

exports.addZ = addZ;
exports.toString = toString;
exports.toDate = toDate;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* component Not a pure module */
