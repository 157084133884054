// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Models$Workhours = require("./Models.bs.js");
var Router$Workhours = require("./Router.bs.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var EditForm$Workhours = require("./editForm.bs.js");

var component = ReasonReact.reducerComponent("NewWork");

function str(prim) {
  return prim;
}

var newWork_start = new Date();

var newWork = {
  id: undefined,
  start: newWork_start,
  duration: 0,
  lunch: 0,
  userid: undefined
};

function findWork(id, workList) {
  var matchesId = function (work) {
    var match = work.id;
    if (match !== undefined) {
      return match === id;
    } else {
      return false;
    }
  };
  var matches = workList.filter(matchesId);
  if (matches.length > 0) {
    return Caml_array.caml_array_get(matches, 0);
  }
  
}

function make(handleAction, workList, id, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* LoadWork */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var match = self.state;
              if (typeof match === "number") {
                switch (match) {
                  case /* Loading */0 :
                      return React.createElement("h1", undefined, "Loading...");
                  case /* Saving */1 :
                      return React.createElement("h1", undefined, "Saving...");
                  case /* Deleting */2 :
                      return React.createElement("h1", undefined, "Deleting...");
                  
                }
              } else if (match.tag) {
                return React.createElement("div", undefined, React.createElement("h1", undefined, "Failed"), React.createElement("p", undefined, match[0]));
              } else {
                return React.createElement("div", undefined, React.createElement("div", undefined, ReasonReact.element(undefined, undefined, EditForm$Workhours.make(match[0], (function (work) {
                                          return Curry._1(self.send, /* SaveWork */Block.__(0, [work]));
                                        }), (function (id) {
                                          return Curry._1(self.send, /* Delete */Block.__(1, [id]));
                                        }), []))));
              }
            }),
          initialState: (function (param) {
              return /* Loading */0;
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                return /* UpdateWithSideEffects */Block.__(2, [
                          /* Loading */0,
                          (function (self) {
                              if (id !== undefined) {
                                var id$1 = id;
                                var match = findWork(id$1, workList);
                                if (match !== undefined) {
                                  return Curry._1(self.send, /* WorkLoaded */Block.__(2, [match]));
                                } else {
                                  return Curry._1(self.send, /* LoadFailed */Block.__(3, ["No work with id " + (id$1 + " found")]));
                                }
                              } else {
                                return Curry._1(self.send, /* WorkLoaded */Block.__(2, [newWork]));
                              }
                            })
                        ]);
              } else {
                switch (action.tag | 0) {
                  case /* SaveWork */0 :
                      var work = action[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* Saving */1,
                                (function (_self) {
                                    Models$Workhours.Work.save(work).then((function (savedWork) {
                                              var match = work.id;
                                              if (match !== undefined) {
                                                Curry._1(handleAction, /* WorkUpdate */Block.__(1, [savedWork]));
                                              } else {
                                                Curry._1(handleAction, /* WorkAdd */Block.__(0, [savedWork]));
                                              }
                                              return Promise.resolve(ReasonReactRouter.push("/"));
                                            })).catch((function (err) {
                                            console.log("Error starting work: ", err);
                                            return Promise.resolve(/* () */0);
                                          }));
                                    return /* () */0;
                                  })
                              ]);
                  case /* Delete */1 :
                      var id$1 = action[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                /* Deleting */2,
                                (function (self) {
                                    Models$Workhours.Work.$$delete(id$1).then((function (param) {
                                              Curry._1(handleAction, /* WorkDelete */Block.__(4, [id$1]));
                                              return Promise.resolve(ReasonReactRouter.push(Router$Workhours.routeToString(/* Home */0)));
                                            })).catch((function (err) {
                                            console.log("Error deleting work: ", err);
                                            return Promise.resolve(Curry._1(self.send, /* LoadFailed */Block.__(3, ["Error deleting work"])));
                                          }));
                                    return /* () */0;
                                  })
                              ]);
                  case /* WorkLoaded */2 :
                      return /* Update */Block.__(0, [/* Loaded */Block.__(0, [action[0]])]);
                  case /* LoadFailed */3 :
                      return /* Update */Block.__(0, [/* Failed */Block.__(1, [action[0]])]);
                  
                }
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.str = str;
exports.newWork = newWork;
exports.findWork = findWork;
exports.make = make;
/* component Not a pure module */
