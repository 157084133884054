// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function work(json) {
  return {
          id: Json_decode.optional((function (param) {
                  return Json_decode.field("id", Json_decode.string, param);
                }), json),
          start: Json_decode.field("start", Json_decode.date, json),
          duration: Json_decode.field("duration", Json_decode.$$float, json),
          lunch: Json_decode.field("lunch", Json_decode.$$float, json),
          userid: Json_decode.optional((function (param) {
                  return Json_decode.field("userid", Json_decode.string, param);
                }), json)
        };
}

function workList(json) {
  return Json_decode.array(work, json);
}

function balance(json) {
  return Json_decode.field("balance", Json_decode.$$float, json);
}

function user(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          username: Json_decode.field("username", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          workhoursPerWeek: Json_decode.field("workhoursperweek", Json_decode.$$float, json),
          balanceFrom: Json_decode.field("balancefrom", Json_decode.date, json)
        };
}

function workRange(json) {
  return {
          startDate: Json_decode.field("startDate", Json_decode.date, json),
          endDate: Json_decode.field("endDate", Json_decode.date, json),
          duration: Json_decode.field("duration", Json_decode.$$float, json)
        };
}

var Decode = {
  work: work,
  workList: workList,
  balance: balance,
  user: user,
  workRange: workRange
};

function work$1(w) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "id",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), w.id)
              ],
              /* :: */[
                /* tuple */[
                  "start",
                  Json_encode.date(w.start)
                ],
                /* :: */[
                  /* tuple */[
                    "duration",
                    w.duration
                  ],
                  /* :: */[
                    /* tuple */[
                      "lunch",
                      w.lunch
                    ],
                    /* :: */[
                      /* tuple */[
                        "userid",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), w.userid)
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]);
}

function user$1(u) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "id",
                u.id
              ],
              /* :: */[
                /* tuple */[
                  "name",
                  u.name
                ],
                /* :: */[
                  /* tuple */[
                    "username",
                    u.username
                  ],
                  /* :: */[
                    /* tuple */[
                      "workhoursperweek",
                      u.workhoursPerWeek
                    ],
                    /* :: */[
                      /* tuple */[
                        "balancefrom",
                        Json_encode.date(u.balanceFrom)
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]);
}

function workRange$1(wr) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "start",
                Json_encode.date(wr.startDate)
              ],
              /* :: */[
                /* tuple */[
                  "end",
                  Json_encode.date(wr.endDate)
                ],
                /* :: */[
                  /* tuple */[
                    "duration",
                    wr.duration
                  ],
                  /* [] */0
                ]
              ]
            ]);
}

var Encode = {
  work: work$1,
  user: user$1,
  workRange: workRange$1
};

exports.Decode = Decode;
exports.Encode = Encode;
/* No side effect */
