// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Printf = require("bs-platform/lib/js/printf.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Models$Workhours = require("./Models.bs.js");
var Router$Workhours = require("./Router.bs.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var DateUtil$Workhours = require("./DateUtil.bs.js");

function str(prim) {
  return prim;
}

var component = ReasonReact.reducerComponent("WorkList");

function formatDate(date) {
  return date.toUTCString();
}

function getWeek(ls) {
  var match = Belt_List.head(ls);
  if (match !== undefined) {
    return DateUtil$Workhours.dateToWeekNo(match.start).toString();
  } else {
    return "Error";
  }
}

function listWork(send, workList) {
  var weekNo = getWeek(workList);
  var x = Belt_List.map(workList, (function (work) {
          return work.duration - work.lunch;
        }));
  return React.createElement("div", {
              key: weekNo
            }, React.createElement("table", {
                  id: "workList"
                }, React.createElement("tbody", undefined, React.createElement("tr", {
                          className: "borderBottom"
                        }, React.createElement("th", {
                              className: "left"
                            }, "Week " + weekNo), React.createElement("th", undefined)), Belt_List.toArray(Belt_List.map(workList, (function (work) {
                                var match = work.id;
                                return React.createElement("tr", {
                                            key: match !== undefined ? match : "0",
                                            onClick: (function (_event) {
                                                return Curry._1(send, /* Edit */Block.__(1, [work]));
                                              })
                                          }, React.createElement("td", {
                                                className: "left"
                                              }, work.start.toUTCString()), React.createElement("td", {
                                                className: "right"
                                              }, Curry._1(Printf.sprintf(/* Format */[
                                                        /* Float */Block.__(8, [
                                                            /* Float_f */0,
                                                            /* No_padding */0,
                                                            /* Lit_precision */[2],
                                                            /* End_of_format */0
                                                          ]),
                                                        "%.2f"
                                                      ]), work.duration - work.lunch)));
                              }))), React.createElement("tr", {
                          className: "borderTop"
                        }, React.createElement("td", undefined), React.createElement("td", {
                              className: "bold right"
                            }, Curry._1(Printf.sprintf(/* Format */[
                                      /* Float */Block.__(8, [
                                          /* Float_f */0,
                                          /* No_padding */0,
                                          /* Lit_precision */[2],
                                          /* End_of_format */0
                                        ]),
                                      "%.2f"
                                    ]), Belt_List.reduce(x, 0, (function (x, y) {
                                        return x + y;
                                      }))))))));
}

function group(list, p) {
  var match = Belt_List.head(list);
  if (match !== undefined) {
    var b = Caml_option.valFromOption(match);
    var comp = function (x) {
      return Caml_obj.caml_equal(Curry._1(p, b), Curry._1(p, x));
    };
    var match$1 = Belt_List.partition(list, comp);
    return Belt_List.concat(/* :: */[
                match$1[0],
                /* [] */0
              ], group(match$1[1], p));
  } else {
    return /* [] */0;
  }
}

function x(workList) {
  return workList.map((function (x) {
                return /* tuple */[
                        DateUtil$Workhours.dateToWeekNo(x.start),
                        x
                      ];
              }));
}

function groupWorkByWeek(workList) {
  return group(workList, (function (x) {
                return DateUtil$Workhours.dateToWeekNo(x.start);
              }));
}

var test = groupWorkByWeek;

function test2(workList, p) {
  return Belt_List.map(groupWorkByWeek(workList), p);
}

function make(handleAction, workList, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var match = self.state;
              var partial_arg = self.send;
              var p = function (param) {
                return listWork(partial_arg, param);
              };
              var workList$1 = Belt_List.fromArray(workList);
              return React.createElement("div", undefined, typeof match === "number" ? (
                            match !== 0 ? React.createElement("p", undefined, "Deleting....") : React.createElement("div", undefined)
                          ) : React.createElement("p", undefined, "Error: " + match[0]), Belt_List.toArray(Belt_List.map(groupWorkByWeek(workList$1), p)));
            }),
          initialState: (function (param) {
              return /* Initial */0;
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                return /* Update */Block.__(0, [/* Initial */0]);
              } else {
                switch (action.tag | 0) {
                  case /* Delete */0 :
                      var match = action[0].id;
                      if (match !== undefined) {
                        var id = match;
                        return /* UpdateWithSideEffects */Block.__(2, [
                                  /* Deleting */1,
                                  (function (self) {
                                      Models$Workhours.Work.$$delete(id).then((function (param) {
                                                Curry._1(handleAction, /* WorkDelete */Block.__(4, [id]));
                                                return Promise.resolve(Curry._1(self.send, /* Deleted */0));
                                              })).catch((function (err) {
                                              console.log("Error deleting work: ", err);
                                              return Promise.resolve(Curry._1(self.send, /* Failed */Block.__(2, ["Error deleting work"])));
                                            }));
                                      return /* () */0;
                                    })
                                ]);
                      } else {
                        return /* Update */Block.__(0, [/* Error */["Cannot delete work with no Id"]]);
                      }
                  case /* Edit */1 :
                      var work = action[0];
                      return /* SideEffects */Block.__(1, [(function (_self) {
                                    return ReasonReactRouter.push(Router$Workhours.routeToString(/* Edit */[work.id]));
                                  })]);
                  case /* Failed */2 :
                      return /* Update */Block.__(0, [/* Error */[action[0]]]);
                  
                }
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.str = str;
exports.component = component;
exports.formatDate = formatDate;
exports.getWeek = getWeek;
exports.listWork = listWork;
exports.group = group;
exports.x = x;
exports.groupWorkByWeek = groupWorkByWeek;
exports.test = test;
exports.test2 = test2;
exports.make = make;
/* component Not a pure module */
