// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Types$Workhours = require("./types.bs.js");
var Models$Workhours = require("./Models.bs.js");
var Router$Workhours = require("./Router.bs.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var UserForm$Workhours = require("./UserForm.bs.js");

var component = ReasonReact.reducerComponent("Settings");

function make(_children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* LoadUser */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var match = self.state;
              if (typeof match === "number") {
                if (match === /* Loading */0) {
                  return React.createElement("h1", undefined, "Loading...");
                } else {
                  return React.createElement("h1", undefined, "Saving...");
                }
              } else if (match.tag) {
                return React.createElement("h1", undefined, match[0]);
              } else {
                return React.createElement("div", undefined, React.createElement("h1", undefined, "Settings"), ReasonReact.element(undefined, undefined, UserForm$Workhours.make(match[0], (function (user) {
                                      return Curry._1(self.send, /* SaveUser */Block.__(1, [user]));
                                    }), [])));
              }
            }),
          initialState: (function (_state) {
              return /* Loading */0;
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                return /* UpdateWithSideEffects */Block.__(2, [
                          /* Loading */0,
                          (function (self) {
                              fetch("/api/user/").then((function (res) {
                                        return res.json();
                                      })).then((function (json) {
                                      var user = Types$Workhours.Decode.user(json);
                                      return Promise.resolve(Curry._1(self.send, /* UserLoaded */Block.__(0, [user])));
                                    }));
                              return /* () */0;
                            })
                        ]);
              } else if (action.tag) {
                var user = action[0];
                return /* UpdateWithSideEffects */Block.__(2, [
                          /* Saving */1,
                          (function (_self) {
                              Promise.resolve(Models$Workhours.User.save(user).then((function (_savedUser) {
                                          return Promise.resolve(ReasonReactRouter.push(Router$Workhours.routeToString(/* Home */0)));
                                        })));
                              return /* () */0;
                            })
                        ]);
              } else {
                return /* Update */Block.__(0, [/* Loaded */Block.__(0, [action[0]])]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
