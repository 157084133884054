// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Config$Workhours = require("./Config.bs.js");
var Models$Workhours = require("./Models.bs.js");
var Router$Workhours = require("./Router.bs.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");

((require('./app.css')));

function str(prim) {
  return prim;
}

var component = ReasonReact.reducerComponent("App");

function make(currentRoute, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* WorkFetch */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var match = self.state;
              var tmp;
              tmp = typeof match === "number" || !(match.tag && match[0].length !== 0) ? React.createElement("div", undefined) : React.createElement("div", {
                      className: "settings"
                    }, React.createElement("button", {
                          className: "settingsButton",
                          title: "Settings",
                          onClick: (function (param) {
                              return ReasonReactRouter.push(Router$Workhours.routeToString(/* Settings */2));
                            })
                        }));
              var match$1 = self.state;
              var tmp$1;
              tmp$1 = typeof match$1 === "number" ? React.createElement("p", undefined, "Loading") : (
                  match$1.tag ? React.createElement("div", undefined, Config$Workhours.routeToComponent(currentRoute, match$1[0], (function (action) {
                                return Curry._1(self.send, action);
                              }))) : React.createElement("div", undefined, match$1[0])
                );
              return React.createElement("div", {
                          className: "App"
                        }, React.createElement("header", undefined, React.createElement("div", undefined), React.createElement("div", {
                                  className: "logo",
                                  onClick: (function (_event) {
                                      return ReasonReactRouter.push(Router$Workhours.routeToString(/* Home */0));
                                    })
                                }, "Workhours"), tmp), React.createElement("section", undefined, tmp$1));
            }),
          initialState: (function (_state) {
              return /* Loading */0;
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                return /* UpdateWithSideEffects */Block.__(2, [
                          /* Loading */0,
                          (function (self) {
                              Models$Workhours.Work.getLatest.then((function (workList) {
                                        return Promise.resolve(Curry._1(self.send, /* WorkFetched */Block.__(2, [workList])));
                                      })).catch((function (_err) {
                                      return Promise.resolve(Curry._1(self.send, /* WorkFetched */Block.__(2, [[]])));
                                    }));
                              return /* () */0;
                            })
                        ]);
              } else {
                switch (action.tag | 0) {
                  case /* WorkAdd */0 :
                      if (typeof state === "number" || !state.tag) {
                        return /* Update */Block.__(0, [/* Error */Block.__(0, ["Failed to add new work"])]);
                      } else {
                        var workList = state[0];
                        workList.push(action[0]);
                        workList.sort((function (x, y) {
                                var match = x.start.getTime();
                                var match$1 = y.start.getTime();
                                if (match < match$1) {
                                  return 1;
                                } else if (match > match$1) {
                                  return -1;
                                } else {
                                  return 0;
                                }
                              }));
                        return /* Update */Block.__(0, [/* Loaded */Block.__(1, [workList])]);
                      }
                  case /* WorkUpdate */1 :
                      var work = action[0];
                      if (typeof state === "number" || !state.tag) {
                        return /* Update */Block.__(0, [/* Error */Block.__(0, ["Failed to update work"])]);
                      } else {
                        var newList = state[0].map((function (w) {
                                if (Caml_obj.caml_equal(w.id, work.id)) {
                                  return work;
                                } else {
                                  return w;
                                }
                              }));
                        return /* Update */Block.__(0, [/* Loaded */Block.__(1, [newList])]);
                      }
                  case /* WorkFetched */2 :
                      return /* Update */Block.__(0, [/* Loaded */Block.__(1, [action[0]])]);
                  case /* WorkFetchFailed */3 :
                      return /* Update */Block.__(0, [/* Error */Block.__(0, [action[0]])]);
                  case /* WorkDelete */4 :
                      var id = action[0];
                      if (typeof state === "number" || !state.tag) {
                        return /* Update */Block.__(0, [/* Error */Block.__(0, ["Failed to delete work"])]);
                      } else {
                        var newList$1 = state[0].filter((function (w) {
                                var match = w.id;
                                if (match !== undefined) {
                                  return match !== id;
                                } else {
                                  return true;
                                }
                              }));
                        return /* Update */Block.__(0, [/* Loaded */Block.__(1, [newList$1])]);
                      }
                  
                }
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.str = str;
exports.component = component;
exports.make = make;
/*  Not a pure module */
