// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Fetch = require("bs-fetch/src/Fetch.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Types$Workhours = require("./types.bs.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");

function doFetch(method_, body) {
  return Fetch.RequestInit.make(method_, {
                "Content-Type": "application/json"
              }, Caml_option.some(body), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(/* () */0);
}

function post(param) {
  return doFetch(/* Post */2, param);
}

function $$delete(param) {
  return doFetch(/* Delete */4, param);
}

function authenticate(res) {
  var status = res.status;
  if (status !== 200) {
    if (status !== 401) {
      return Js_exn.raiseError(res.statusText);
    } else {
      ReasonReactRouter.push("/login");
      return Js_exn.raiseError(res.statusText);
    }
  } else {
    return res.json();
  }
}

function save(work) {
  return fetch("/api/work", doFetch(/* Post */2, JSON.stringify(Types$Workhours.Encode.work(work)))).then(authenticate).then((function (json) {
                return Promise.resolve(Types$Workhours.Decode.work(json));
              }));
}

function saveRange(workRange) {
  return fetch("/api/work/range", doFetch(/* Post */2, JSON.stringify(Types$Workhours.Encode.workRange(workRange)))).then(authenticate).then((function (json) {
                return Promise.resolve(Types$Workhours.Decode.workList(json));
              }));
}

function $$delete$1(id) {
  return fetch("/api/work", doFetch(/* Delete */4, "{\"id\": \"" + (id + "\"}"))).then(authenticate).then((function (_t) {
                return Promise.resolve(/* () */0);
              }));
}

var getLatest = fetch("/api/work/latest").then(authenticate).then((function (json) {
        return Promise.resolve(Types$Workhours.Decode.workList(json));
      }));

var balance = fetch("/api/work/balance").then(authenticate).then((function (json) {
        return Promise.resolve(Types$Workhours.Decode.balance(json));
      }));

var Work = {
  save: save,
  saveRange: saveRange,
  $$delete: $$delete$1,
  getLatest: getLatest,
  balance: balance
};

function save$1(user) {
  return fetch("/api/user", doFetch(/* Post */2, JSON.stringify(Types$Workhours.Encode.user(user)))).then(authenticate).then((function (param) {
                return Promise.resolve(/* () */0);
              }));
}

var User = {
  save: save$1
};

exports.doFetch = doFetch;
exports.post = post;
exports.$$delete = $$delete;
exports.authenticate = authenticate;
exports.Work = Work;
exports.User = User;
/* getLatest Not a pure module */
