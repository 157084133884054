// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/src/ReasonReact.js");
var Home$Workhours = require("./home.bs.js");
var Login$Workhours = require("./Login.bs.js");
var NotFound$Workhours = require("./NotFound.bs.js");
var Settings$Workhours = require("./Settings.bs.js");
var NewOrRange$Workhours = require("./newOrRange.bs.js");

function routeToComponent(route, workList, handleAction) {
  if (typeof route === "number") {
    switch (route) {
      case /* Home */0 :
          return ReasonReact.element(undefined, undefined, Home$Workhours.make(workList, handleAction, []));
      case /* Login */1 :
          return ReasonReact.element(undefined, undefined, Login$Workhours.make([]));
      case /* Settings */2 :
          return ReasonReact.element(undefined, undefined, Settings$Workhours.make([]));
      case /* NotFound */3 :
          return ReasonReact.element(undefined, undefined, NotFound$Workhours.make([]));
      
    }
  } else {
    return ReasonReact.element(undefined, undefined, NewOrRange$Workhours.make(handleAction, workList, route[0], []));
  }
}

exports.routeToComponent = routeToComponent;
/* ReasonReact Not a pure module */
