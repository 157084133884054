// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Router$Workhours = require("./Router.bs.js");
var Balance$Workhours = require("./Balance.bs.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var WorkList$Workhours = require("./workList.bs.js");
var CurrentWork$Workhours = require("./currentWork.bs.js");

var component = ReasonReact.statelessComponent("Home");

function make(workList, handleAction, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Balance$Workhours.make([])), ReasonReact.element(undefined, undefined, CurrentWork$Workhours.make(workList, handleAction, [])), ReasonReact.element(undefined, undefined, WorkList$Workhours.make(handleAction, workList, [])), React.createElement("button", {
                              className: "addBtn",
                              onClick: (function (_event) {
                                  return ReasonReactRouter.push(Router$Workhours.routeToString(/* Edit */[undefined]));
                                })
                            }, "+"));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
