// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Printf = require("bs-platform/lib/js/printf.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Models$Workhours = require("./Models.bs.js");

function str(prim) {
  return prim;
}

var component = ReasonReact.reducerComponent("Balance");

function make(_children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Load */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var match = self.state;
              if (typeof match === "number") {
                if (match === /* Initial */0) {
                  return React.createElement("p", undefined, "Initial");
                } else {
                  return React.createElement("p", undefined, "Loading");
                }
              } else if (match.tag) {
                return React.createElement("p", undefined, "Fetching balance failed: " + match[0]);
              } else {
                return React.createElement("p", {
                            className: "center"
                          }, Curry._1(Printf.sprintf(/* Format */[
                                    /* String_literal */Block.__(11, [
                                        "Balance: ",
                                        /* Float */Block.__(8, [
                                            /* Float_f */0,
                                            /* No_padding */0,
                                            /* Lit_precision */[2],
                                            /* End_of_format */0
                                          ])
                                      ]),
                                    "Balance: %.2f"
                                  ]), match[0]));
              }
            }),
          initialState: (function (param) {
              return /* Initial */0;
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                return /* UpdateWithSideEffects */Block.__(2, [
                          /* Loading */1,
                          (function (self) {
                              Models$Workhours.Work.balance.then((function (balance) {
                                      return Promise.resolve(Curry._1(self.send, /* Loaded */Block.__(0, [balance])));
                                    }));
                              return /* () */0;
                            })
                        ]);
              } else if (action.tag) {
                return /* Update */Block.__(0, [/* Failed */Block.__(1, [action[0]])]);
              } else {
                return /* Update */Block.__(0, [/* Loaded */Block.__(0, [action[0]])]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.str = str;
exports.component = component;
exports.make = make;
/* component Not a pure module */
