// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");

function str(prim) {
  return prim;
}

var component = ReasonReact.statelessComponent("TimeInput");

function addZ(num) {
  if (num < 10) {
    return "0" + num.toString();
  } else {
    return num.toString();
  }
}

function toString(date) {
  return addZ(date.getHours()) + (":" + addZ(date.getMinutes()));
}

function toDate(date, timeString) {
  var vals = timeString.split(":");
  var hours = Caml_array.caml_array_get(vals, 0);
  var minutes = Caml_array.caml_array_get(vals, 1);
  date.setHours(Number(hours));
  date.setMinutes(Number(minutes));
  return date;
}

function make(value, id, onChange, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return React.createElement("input", {
                          id: id,
                          type: "time",
                          value: toString(value),
                          onChange: (function ($$event) {
                              return Curry._1(onChange, toDate(value, $$event.target.value));
                            })
                        });
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.str = str;
exports.component = component;
exports.addZ = addZ;
exports.toString = toString;
exports.toDate = toDate;
exports.make = make;
/* component Not a pure module */
