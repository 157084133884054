// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var ReactDOMRe = require("reason-react/src/ReactDOMRe.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");

function urlToRoute(url) {
  var match = url.path;
  if (match) {
    switch (match[0]) {
      case "edit" :
          var match$1 = match[1];
          if (match$1) {
            if (match$1[1]) {
              return /* NotFound */3;
            } else {
              return /* Edit */[match$1[0]];
            }
          } else {
            return /* Edit */[undefined];
          }
      case "login" :
          if (match[1]) {
            return /* NotFound */3;
          } else {
            return /* Login */1;
          }
      case "settings" :
          if (match[1]) {
            return /* NotFound */3;
          } else {
            return /* Settings */2;
          }
      default:
        return /* NotFound */3;
    }
  } else {
    return /* Home */0;
  }
}

function routeToString(route) {
  if (typeof route === "number") {
    switch (route) {
      case /* Home */0 :
          return "/";
      case /* Login */1 :
          return "/login";
      case /* Settings */2 :
          return "/settings";
      case /* NotFound */3 :
          return "/404";
      
    }
  } else {
    var match = route[0];
    if (match !== undefined) {
      return "/edit/" + match;
    } else {
      return "/edit";
    }
  }
}

var component = ReasonReact.reducerComponent("WithRouter");

function make(children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              var watchId = ReasonReactRouter.watchUrl((function (url) {
                      return Curry._1(self.send, /* ChangeUrl */[urlToRoute(url)]);
                    }));
              return Curry._1(self.onUnmount, (function (param) {
                            return ReasonReactRouter.unwatchUrl(watchId);
                          }));
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return Curry._1(children, param.state.currentRoute);
            }),
          initialState: (function (param) {
              return {
                      currentRoute: urlToRoute(ReasonReactRouter.dangerouslyGetInitialUrl(/* () */0))
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              return /* Update */Block.__(0, [{
                          currentRoute: action[0]
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var WithRouter = {
  component: component,
  make: make
};

var component$1 = ReasonReact.statelessComponent("Link");

function make$1(route, classNameOpt, children) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (self) {
              var href = routeToString(route);
              var onClick = function (handle) {
                return Curry._1(handle, (function ($$event, _self) {
                              $$event.preventDefault();
                              return ReasonReactRouter.push(href);
                            }));
              };
              return ReactDOMRe.createElementVariadic("a", {
                          className: className,
                          href: href,
                          onClick: onClick(self.handle)
                        }, children);
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var Link = {
  component: component$1,
  make: make$1
};

var component$2 = ReasonReact.statelessComponent("NavLink");

function make$2(route, activeRoute, className, children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, make((function (currentRoute) {
                                var activeRoute$1 = activeRoute !== undefined ? activeRoute : route;
                                var className$1 = Cn.make(/* :: */[
                                      Cn.unpack(className),
                                      /* :: */[
                                        Cn.ifTrue("active", Caml_obj.caml_equal(activeRoute$1, currentRoute)),
                                        /* [] */0
                                      ]
                                    ]);
                                return ReasonReact.element(undefined, undefined, make$1(route, className$1, children));
                              })));
            }),
          initialState: component$2.initialState,
          retainedProps: component$2.retainedProps,
          reducer: component$2.reducer,
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var NavLink = {
  component: component$2,
  make: make$2
};

exports.urlToRoute = urlToRoute;
exports.routeToString = routeToString;
exports.WithRouter = WithRouter;
exports.Link = Link;
exports.NavLink = NavLink;
/* component Not a pure module */
