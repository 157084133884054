// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var NewWork$Workhours = require("./newWork.bs.js");
var NewWorkRange$Workhours = require("./newWorkRange.bs.js");

var component = ReasonReact.reducerComponent("SingleOrRange");

function title(id) {
  if (id !== undefined) {
    return "Edit Work";
  } else {
    return "New Work";
  }
}

function make(handleAction, workList, id, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var match = self.state;
              return React.createElement("div", undefined, React.createElement("h1", undefined, title(id)), id !== undefined ? React.createElement("div", undefined) : React.createElement("label", undefined, "Range", React.createElement("input", {
                                    checked: self.state === /* Range */1,
                                    type: "checkbox",
                                    onChange: (function (param) {
                                        return Curry._1(self.send, self.state === /* Range */1 ? /* ToSingle */0 : /* ToRange */1);
                                      })
                                  })), match ? ReasonReact.element(undefined, undefined, NewWorkRange$Workhours.Jsx2.make(handleAction, [])) : ReasonReact.element(undefined, undefined, NewWork$Workhours.make(handleAction, workList, id, [])));
            }),
          initialState: (function (param) {
              return /* Single */0;
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (action) {
                return /* Update */Block.__(0, [/* Range */1]);
              } else {
                return /* Update */Block.__(0, [/* Single */0]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.title = title;
exports.make = make;
/* component Not a pure module */
