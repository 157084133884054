// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Printf = require("bs-platform/lib/js/printf.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var DateInput$Workhours = require("./DateInput.bs.js");
var TimeInput$Workhours = require("./TimeInput.bs.js");

function calcEndDate(startDate, duration) {
  var endDate = new Date(startDate.getTime());
  endDate.setHours(startDate.getHours() + Math.floor(duration));
  endDate.setMinutes(startDate.getMinutes() + duration % 1 * 60);
  return endDate;
}

function calcDuration(startDate, endDate) {
  var diffMs = endDate.getTime() - startDate.getTime();
  return diffMs / 1000 / 60 / 60;
}

function workToFormData(work) {
  return {
          id: work.id,
          startDate: work.start,
          endDate: calcEndDate(work.start, work.duration),
          duration: Curry._1(Printf.sprintf(/* Format */[
                    /* Float */Block.__(8, [
                        /* Float_g */9,
                        /* No_padding */0,
                        /* Lit_precision */[12],
                        /* End_of_format */0
                      ]),
                    "%.12g"
                  ]), work.duration),
          lunch: Curry._1(Printf.sprintf(/* Format */[
                    /* Float */Block.__(8, [
                        /* Float_g */9,
                        /* No_padding */0,
                        /* Lit_precision */[12],
                        /* End_of_format */0
                      ]),
                    "%.12g"
                  ]), work.lunch),
          userid: work.userid
        };
}

function formatFloatString(str) {
  if (str === "") {
    return "0";
  } else {
    return str.replace(",", ".");
  }
}

function formDataToWork(formData) {
  return {
          id: formData.id,
          start: formData.startDate,
          duration: Number(formatFloatString(formData.duration)),
          lunch: Number(formatFloatString(formData.lunch)),
          userid: formData.userid
        };
}

function isNan(fieldName, num) {
  if (isNaN(num)) {
    return /* :: */[
            fieldName + " is not a valid number",
            /* [] */0
          ];
  } else {
    return /* [] */0;
  }
}

function isPositive(fieldName, num) {
  if (num < 0) {
    return /* :: */[
            fieldName + " must not be negative",
            /* [] */0
          ];
  } else {
    return /* [] */0;
  }
}

function validateFloat(fieldName, value) {
  var num = Number(formatFloatString(value));
  return Belt_List.concat(isNan(fieldName, num), isPositive(fieldName, num));
}

function validate(formData) {
  var duration = validateFloat("Duration", formData.duration);
  var lunch = validateFloat("Lunch", formData.lunch);
  return Belt_List.concat(duration, lunch);
}

function str(prim) {
  return prim;
}

var component = ReasonReact.reducerComponent("EditForm");

function make(work, saveAction, deleteAction, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var errors = self.state.validationErrors;
              var match = work.id;
              var match$1 = work.id;
              return React.createElement("div", undefined, errors ? $$Array.of_list(errors).map((function (error, i) {
                                  return React.createElement("div", {
                                              key: i.toString(),
                                              className: "error"
                                            }, error);
                                })) : React.createElement("div", undefined), React.createElement("form", {
                              id: "registerHours"
                            }, React.createElement("label", {
                                  htmlFor: "startDate"
                                }, "Start date"), ReasonReact.element(undefined, undefined, DateInput$Workhours.Jsx2.make(self.state.formData.startDate, "startDate", (function (date) {
                                        return Curry._1(self.send, /* ChangeStartDate */Block.__(0, [date]));
                                      }), [])), React.createElement("label", {
                                  htmlFor: "startTime"
                                }, "Start time"), ReasonReact.element(undefined, undefined, TimeInput$Workhours.make(self.state.formData.startDate, "startTime", (function (date) {
                                        return Curry._1(self.send, /* ChangeStartTime */Block.__(1, [date]));
                                      }), [])), React.createElement("label", {
                                  htmlFor: "endTime"
                                }, "End time"), ReasonReact.element(undefined, undefined, TimeInput$Workhours.make(self.state.formData.endDate, "endTime", (function (date) {
                                        return Curry._1(self.send, /* ChangeEndTime */Block.__(2, [date]));
                                      }), [])), React.createElement("label", {
                                  htmlFor: "duration"
                                }, "Duration"), React.createElement("input", {
                                  id: "duration",
                                  value: self.state.formData.duration,
                                  onChange: (function ($$event) {
                                      return Curry._1(self.send, /* ChangeDuration */Block.__(3, [$$event.target.value]));
                                    })
                                }), React.createElement("label", {
                                  htmlFor: "lunch"
                                }, "Lunch"), React.createElement("input", {
                                  id: "lunch",
                                  value: self.state.formData.lunch,
                                  onChange: (function ($$event) {
                                      return Curry._1(self.send, /* ChangeLunch */Block.__(4, [$$event.target.value]));
                                    })
                                }), React.createElement("div", {
                                  className: "actionButtons"
                                }, match !== undefined ? React.createElement("button", {
                                        className: "button button--danger actionButtons__delete",
                                        type: "button",
                                        onClick: (function (_event) {
                                            return Curry._1(self.send, /* Delete */1);
                                          })
                                      }, "Delete") : React.createElement("div", undefined), React.createElement("button", {
                                      className: "button actionButtons__cancel",
                                      type: "button",
                                      onClick: (function (_event) {
                                          return ReasonReactRouter.push("/");
                                        })
                                    }, "Cancel"), React.createElement("button", {
                                      className: "button button--primary actionButtons__add",
                                      type: "button",
                                      onClick: (function (_event) {
                                          return Curry._1(self.send, /* Validate */0);
                                        })
                                    }, match$1 !== undefined ? "Save" : "Add"))));
            }),
          initialState: (function (param) {
              return {
                      formData: workToFormData(work),
                      validationErrors: /* [] */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                if (action === /* Validate */0) {
                  var errors = validate(state.formData);
                  if (errors) {
                    return /* Update */Block.__(0, [{
                                formData: state.formData,
                                validationErrors: errors
                              }]);
                  } else {
                    Curry._1(saveAction, formDataToWork(state.formData));
                    return /* NoUpdate */0;
                  }
                } else {
                  var match = state.formData.id;
                  if (match !== undefined) {
                    Curry._1(deleteAction, match);
                    return /* NoUpdate */0;
                  } else {
                    return /* NoUpdate */0;
                  }
                }
              } else {
                switch (action.tag | 0) {
                  case /* ChangeStartDate */0 :
                      var date = action[0];
                      date.setHours(state.formData.startDate.getHours());
                      date.setMinutes(state.formData.startDate.getMinutes());
                      date.setSeconds(state.formData.startDate.getSeconds());
                      date.setMilliseconds(state.formData.startDate.getMilliseconds());
                      var init = state.formData;
                      return /* Update */Block.__(0, [{
                                  formData: {
                                    id: init.id,
                                    startDate: date,
                                    endDate: init.endDate,
                                    duration: init.duration,
                                    lunch: init.lunch,
                                    userid: init.userid
                                  },
                                  validationErrors: state.validationErrors
                                }]);
                  case /* ChangeStartTime */1 :
                      var date$1 = action[0];
                      var init$1 = state.formData;
                      return /* Update */Block.__(0, [{
                                  formData: {
                                    id: init$1.id,
                                    startDate: date$1,
                                    endDate: init$1.endDate,
                                    duration: calcDuration(state.formData.startDate, date$1).toString(),
                                    lunch: init$1.lunch,
                                    userid: init$1.userid
                                  },
                                  validationErrors: state.validationErrors
                                }]);
                  case /* ChangeEndTime */2 :
                      var date$2 = action[0];
                      var init$2 = state.formData;
                      return /* Update */Block.__(0, [{
                                  formData: {
                                    id: init$2.id,
                                    startDate: init$2.startDate,
                                    endDate: date$2,
                                    duration: calcDuration(state.formData.startDate, date$2).toString(),
                                    lunch: init$2.lunch,
                                    userid: init$2.userid
                                  },
                                  validationErrors: state.validationErrors
                                }]);
                  case /* ChangeDuration */3 :
                      var duration = action[0];
                      var num = Number(formatFloatString(duration));
                      var endDate = isNaN(num) ? state.formData.startDate : calcEndDate(state.formData.startDate, num);
                      var init$3 = state.formData;
                      return /* Update */Block.__(0, [{
                                  formData: {
                                    id: init$3.id,
                                    startDate: init$3.startDate,
                                    endDate: endDate,
                                    duration: duration,
                                    lunch: init$3.lunch,
                                    userid: init$3.userid
                                  },
                                  validationErrors: state.validationErrors
                                }]);
                  case /* ChangeLunch */4 :
                      var init$4 = state.formData;
                      return /* Update */Block.__(0, [{
                                  formData: {
                                    id: init$4.id,
                                    startDate: init$4.startDate,
                                    endDate: init$4.endDate,
                                    duration: init$4.duration,
                                    lunch: action[0],
                                    userid: init$4.userid
                                  },
                                  validationErrors: state.validationErrors
                                }]);
                  
                }
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.calcEndDate = calcEndDate;
exports.calcDuration = calcDuration;
exports.workToFormData = workToFormData;
exports.formatFloatString = formatFloatString;
exports.formDataToWork = formDataToWork;
exports.isNan = isNan;
exports.isPositive = isPositive;
exports.validateFloat = validateFloat;
exports.validate = validate;
exports.str = str;
exports.component = component;
exports.make = make;
/* component Not a pure module */
